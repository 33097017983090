// JavaScript source code
// JavaScript source code
import React from "react";
import Nav from "../components/Nav";
import MyExperiences from "../components/MyExperiences"


function Experience() {
    return (
        <div>
            <Nav />
            <MyExperiences/>
        </div>
    );
}

export default Experience;

