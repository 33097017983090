// JavaScript source code
import pic1 from '../images/Profile.jpg'
import pic2 from '../images/Pic1.jpg'
import pic4 from '../images/Pic3.jpg'
import pic5 from '../images/Pic4.jpg'


export const SliderData = [
    {
        image: pic1
    },
    {
        image: pic2
    },
    {
        image: pic4
    },
    {
        image: pic5
    }

];